<script>
import { Doughnut, mixins } from 'vue-chartjs';
import { chartTooltipStyles } from '@/core/setting';

const { reactiveProp } = mixins;

export default {
  name: 'NutritionPieChart',
  mixins: [Doughnut, reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: true,
      legend: {
        display: false,
      },
      hover: {
        mode: null,
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      tooltips: {
        ...chartTooltipStyles,
        callbacks: {
          label: (tooltipItems, data) => {
            const { index } = tooltipItems;
            const lbl = data.labels[index];
            const val = data.datasets[0].data[index];
            return `${lbl}: ${val}%`;
          },
        },
      },
    });
  },
};
</script>
